import React from "react";
import LandingSection from "../../components/LandingSection";
import Grid from "../../voyage-ui/building-blocks/Grid/Grid";
import UpdateCard from "../../components/UpdateCard";
import Layout from "../../voyage-ui/building-blocks/Layout/Layout";
import css from "./releases.module.css";

export default function Releases() {
  return (
    <LandingSection dark>
      <Layout vertical gap="var(--c5)">
        <h3>Updates</h3>
        <Grid desktopColumns={2} tabletColumns={2} mobileColumns={1}>
          <div className={css["reveal-7"]}>
            <UpdateCard
              to="/forge"
              image="https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/90fcdc2e-ac3c-4ccd-d6d9-7ec251938f00/public"
            />
          </div>
          <div className={css["reveal-6"]}>
            <UpdateCard
              to="/ember"
              image="https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/9c878856-bd7c-4130-6090-685232abac00/public"
            />
          </div>
          <div className={css["reveal-5"]}>
            <UpdateCard
              to="/pathfinder"
              image="https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/38ce82c7-12b3-4b6e-ac3c-0fd48bc13e00/public"
            />
          </div>
          <div className={css["reveal-4"]}>
            <UpdateCard
              to="/renaissance"
              image="https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/abab52f0-92dd-4815-7a45-80d0250eac00/public"
            />
          </div>
          <div className={css["reveal-3"]}>
            <UpdateCard
              to="/phoenix"
              image="https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/9ee501d4-c62c-457f-6bab-3e974e488a00/public"
            />
          </div>
          <div className={css["reveal-2"]}>
            <UpdateCard
              to="/unchained"
              image="https://imagedelivery.net/DPSHeCXswmvoQXsmqJ-mDA/2b0e9725-fd4f-4c33-fe1b-7b84ed18c900/public"
            />
          </div>
        </Grid>
      </Layout>
    </LandingSection>
  );
}
